@import "./variables";
@import "../../node_modules/material-angular-components/src/stylesheets/style.scss";

html:root, *:root, :root {
    // primary color
    --mdc-theme-primary-50: #E0F3FA;
    --mdc-theme-primary-100: #B3E2F2;
    --mdc-theme-primary-200: #80CFE9;
    --mdc-theme-primary-300: #4DBCE0;
    --mdc-theme-primary-400: #26ADDA;
    --mdc-theme-primary-500: #{$color-primary};
    --mdc-theme-primary-600: #0097CE;
    --mdc-theme-primary-700: #008DC8;
    --mdc-theme-primary-800: #0083C2;
    --mdc-theme-primary-900: #0072B7;
    --mdc-theme-primary-A100: #E0F2FF;
    --mdc-theme-primary-A200: #ADDCFF;
    --mdc-theme-primary-A400: #7AC6FF;
    --mdc-theme-primary-A700: #61BBFF;
    --mdc-theme-primary-50-ct: #000000;
    --mdc-theme-primary-100-ct: #000000;
    --mdc-theme-primary-200-ct: #000000;
    --mdc-theme-primary-300-ct: #000000;
    --mdc-theme-primary-400-ct: #ffffff;
    --mdc-theme-primary-500-ct: #ffffff;
    --mdc-theme-primary-600-ct: #ffffff;
    --mdc-theme-primary-700-ct: #ffffff;
    --mdc-theme-primary-800-ct: #ffffff;
    --mdc-theme-primary-900-ct: #ffffff;
    --mdc-theme-primary-A100-ct: #000000;
    --mdc-theme-primary-A200-ct: #000000;
    --mdc-theme-primary-A400-ct: #000000;
    --mdc-theme-primary-A700-ct: #000000;
    --mdc-theme-primary: #{$color-primary};
    --mdc-theme-primary-rgb: #{toRGB($color-primary)};

    // secondary color
    --mdc-theme-secondary-50: #E6E7E8;
    --mdc-theme-secondary-100: #C0C4C6;
    --mdc-theme-secondary-200: #969CA0;
    --mdc-theme-secondary-300: #6B747A;
    --mdc-theme-secondary-400: #4C575E;
    --mdc-theme-secondary-500: #{$color-secondary};
    --mdc-theme-secondary-600: #27333B;
    --mdc-theme-secondary-700: #212C32;
    --mdc-theme-secondary-800: #1B242A;
    --mdc-theme-secondary-900: #10171C;
    --mdc-theme-secondary-A100: #60B8FF;
    --mdc-theme-secondary-A200: #2DA2FF;
    --mdc-theme-secondary-A400: #008AF9;
    --mdc-theme-secondary-A700: #007CE0;
    --mdc-theme-secondary-50-ct: #000000;
    --mdc-theme-secondary-100-ct: #000000;
    --mdc-theme-secondary-200-ct: #000000;
    --mdc-theme-secondary-300-ct: #ffffff;
    --mdc-theme-secondary-400-ct: #ffffff;
    --mdc-theme-secondary-500-ct: #ffffff;
    --mdc-theme-secondary-600-ct: #ffffff;
    --mdc-theme-secondary-700-ct: #ffffff;
    --mdc-theme-secondary-800-ct: #ffffff;
    --mdc-theme-secondary-900-ct: #ffffff;
    --mdc-theme-secondary-A100-ct: #000000;
    --mdc-theme-secondary-A200-ct: #000000;
    --mdc-theme-secondary-A400-ct: #ffffff;
    --mdc-theme-secondary-A700-ct: #ffffff;
    --mdc-theme-secondary: #{$color-secondary};
    --mdc-theme-secondary-rgb: #{toRGB($color-secondary)};
}
